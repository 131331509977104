import { ReactNode } from "react";

// Base

export interface BaseModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

// Common

export interface SelectOption<T extends string | number = string> {
  label: string;
  value: T;
}

export interface TabsOption<TabsValue = string> {
  label: string;
  value: TabsValue;
  disabled?: boolean;
  isVisible?: boolean;
  component: ReactNode;
}

export interface Pagination {
  page: number;
  pageSize: number;
  total: number;
}

export type DetailsPageTabs<Tabs extends string, Component = ReactNode> = Record<
  Tabs,
  { isVisible: boolean; component: Component; label: string }
>;

// Enums

export enum PageTypes {
  VendorOpportunities = "vo",
  CreditSubmission = "cs",
}

export enum ContentViewType {
  Desktop = "Desktop",
  Mobile = "Mobile",
}

// Utils

export type Nullable<T> = null | T;

export type ValueOf<T, K extends keyof T> = T[K];

export enum PrescreenTypes {
  Prescreen = "Prescreen",
  LenderWaterfall = "LenderWaterfall",
  Demo = "Demo",
  Off = "Off",
}

export enum ModalsKeys {
  Load = "load",
  CreateEquipment = "createEquipment",
  Confirmation = "confirmation",
  FinanceForm = "financeFormDialog",
  PreviewFinanceForm = "previewFinanceForm",
  ProposalProductsDialog = "proposalProductsDialog",
  ProposalMenuOptionDetailsDialog = "proposalMenuOptionDetailsDialog",

  DeskingConfirmSaveDraftDialog = "confirmSaveDraftDialog",
  DeskingNotifyAboutRemovedProductsDialog = "deskingNotifyAboutRemovedProductsDialog",

  ProductCatalogDialog = "productCatalogDialog",

  ConstructorPricingInfoDialog = "constructorPricingInfoDialog",

  AftermarketAddEditMenuOptionDialog = "addEditMenuOptionDialog",
  AftermarketSaveAsDialog = "aftermarketSaveAsDialog",
  AftermarketShareMenuConfirmationDialog = "aftermarketShareMenuConfirmationDialog",

  AftermarketPartnerAddDealerDialog = "aftermarketPartnerAddDealerDialog",

  PrescreenProgramsDialog = "programsDialog",

  CreateUpdateInvoiceDialog = "createUpdateInvoiceDialog",
}

// Redux

type InferValueTypes<T> = T extends { [key: string]: infer U } ? U : never;
export type ActionTypes<T extends { [key: string]: (...args: any) => any }> = ReturnType<InferValueTypes<T>>;
