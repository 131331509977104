import { FinanceProgram, Location } from "@trnsact/trnsact-shared-types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import {
  AftermarketMenu,
  AftermarketMenuOption,
  BuilderViewType,
  Equipment,
  FormSections,
  PaymentOptionTerm,
  Pricing,
  ProductConfig,
  Term,
} from "../types";
import { Section } from "./types";

const setProposalMenus = (payload: { menuOptions: AftermarketMenu[] }) =>
  ({
    type: "DESKING_SET_PROPOSAL_MENUS",
    payload,
  } as const);

const toggleIsProposalProductsLoading = (payload: { isLoading: boolean }) =>
  ({
    type: "DESKING_TOGGLE_PROPOSALS_PRODUCTS_LOADING",
    payload,
  } as const);

const resetMenuBuilder = () =>
  ({
    type: "DESKING_RESET_MENU_BUILDER",
  } as const);

const updateMenuBuilderViewType = (payload: { type: BuilderViewType }) =>
  ({
    type: "DESKING_UPDATE_MENU_BUILDER_VIEW_TYPE",
    payload,
  } as const);

const setMenuToBuilder = (payload: { id: string; terms: string[] }) =>
  ({
    type: "DESKING_SET_MENU_TO_BUILDER",
    payload,
  } as const);

const setFinancePricing = (payload: { results: Pricing[] }) =>
  ({
    type: "DESKING_SET_FINANCE_PRICING",
    payload,
  } as const);

const setMenuPricing = (payload: { results: Pricing[] }) =>
  ({
    type: "DESKING_SET_MENU_PRICING",
    payload,
  } as const);

const removeFinancePricing = (payload: { terms: Term[] }) =>
  ({
    type: "DESKING_REMOVE_FINANCE_PRICING",
    payload,
  } as const);

const removeMenuPricing = (payload: { terms: Term[] }) =>
  ({
    type: "DESKING_REMOVE_MENU_PRICING",
    payload,
  } as const);

const setFinancePrograms = (payload: { programs: FinanceProgram[] }) =>
  ({
    type: "DESKING_SET_FINANCE_PROGRAMS",
    payload,
  } as const);

const setCreditAppLocationData = (payload: { creditAppLocationData: Location }) =>
  ({
    type: "DESKING_SET_CREDIT_APP_LOCATION_DATA",
    payload,
  } as const);

const setEquipmentData = (payload: { equipments: Equipment[] }) =>
  ({
    type: "DESKING_SET_EQUIPMENT_DATA",
    payload,
  } as const);

const setCurrentEquipmentData = (payload: { id: string }) =>
  ({
    type: "DESKING_SET_CURRENT_EQUIPMENT_DATA",
    payload,
  } as const);

const updateProducts = (payload: { products: ProposalProduct[] }) =>
  ({
    type: "DESKING_UPDATE_PROPOSALS_PRODUCTS",
    payload,
  } as const);

const setProducts = (payload: { products: ProposalProduct[] }) =>
  ({
    type: "DESKING_SET_PROPOSALS_PRODUCTS",
    payload,
  } as const);

const setMenuOptions = (payload: { menuOptions: AftermarketMenuOption[] }) =>
  ({
    type: "DESKING_SET_MENU_OPTIONS",
    payload,
  } as const);

const setOrderOfProductInMenu = (payload: { menuName: string; from: number; to: number }) =>
  ({
    type: "DESKING_SET_ORDER_OF_PRODUCT_IN_MENU",
    payload,
  } as const);

const unselectProduct = (payload: { productId: string }) =>
  ({
    type: "DESKING_UNSELECT_PROPOSALS_PRODUCTS",
    payload,
  } as const);

const setProposalCreated = (payload: { isCreated: boolean }) =>
  ({
    type: "DESKING_SET_PROPOSAL_CREATED",
    payload,
  } as const);

const setMenuTerm = (payload: Partial<PaymentOptionTerm>) =>
  ({
    type: "DESKING_SET_MENU_TERM",
    payload,
  } as const);

const addMenuProduct = (payload: { menuType: string; product: ProposalProduct }) =>
  ({
    type: "DESKING_ADD_PRODUCT",
    payload,
  } as const);

const removeMenuProduct = (payload: { menuName: string; productId: string }) =>
  ({
    type: "DESKING_REMOVE_PRODUCT",
    payload,
  } as const);

const updateRecalculate = () =>
  ({
    type: "DESKING_UPDATE_RECALCULATE",
  } as const);

const updateSectionLayout = (payload: { section: FormSections; key: keyof Section; value: boolean }) =>
  ({
    type: "DESKING_UPDATE_SECTION_LAYOUT",
    payload,
  } as const);

const selectMenuOption = (payload: { optionName: string }) =>
  ({
    type: "DESKING_LAYOUT_SELECT_MENU_OPTION",
    payload,
  } as const);

const resetStructureAndMenuOptions = () =>
  ({
    type: "DESKING_RESET_STRUCTURE_AND_MENU_OPTIONS",
  } as const);

const clearState = () =>
  ({
    type: "DESKING_CLEAR",
  } as const);

const setIsNeedToAutoFillProductsDynamicFields = () =>
  ({
    type: "DESKING_SET_IS_NEED_TO_AUTO_FILL_PRODUCTS_DYNAMIC_FIELDS",
  } as const);

const updateProductConfiguration = (payload: { productId: string; data: Partial<ProductConfig> }) =>
  ({
    type: "DESKING_UPDATE_PRODUCT_CONFIGURATION",
    payload,
  } as const);

const updateAllSelectedProductsConfiguration = (payload: { data: Partial<ProductConfig> }) =>
  ({
    type: "DESKING_UPDATE_ALL_PRODUCTS_CONFIGURATION",
    payload,
  } as const);

const updateProductConfigurationInMenu = (payload: {
  productId: string;
  menuName: string;
  data: Partial<ProductConfig>;
}) =>
  ({
    type: "DESKING_UPDATE_PRODUCT_CONFIGURATION_IN_MENU",
    payload,
  } as const);

const setProductsConfigFromExistingMenu = (payload: { terms: string[]; data: AftermarketMenuOption[] }) =>
  ({
    type: "DESKING_SET_PRODUCTS_CONFIGURATION_FROM_EXISTING_MENU",
    payload,
  } as const);

const addSelectedProductsToMenu = () =>
  ({
    type: "DESKING_ADD_SELECTED_PRODUCTS_TO_MENU",
  } as const);

const runJsonEngine = () =>
  ({
    type: "DESKING_RUN_ENGINE",
  } as const);

const addMenuTerm = (payload: { term: string; menuId: string }) =>
  ({
    type: "DESKING_ADD_TERM_FROM_MENU_BUILDER",
    payload,
  } as const);

const removeMenuTerm = (payload: { term: string }) =>
  ({
    type: "DESKING_REMOVE_TERM_FROM_MENU_BUILDER",
    payload,
  } as const);

const removeProductsFromMenu = (payload: { ids: string[] }) =>
  ({
    type: "DESKING_REMOVE_PRODUCTS_FROM_MENU",
    payload,
  } as const);

const toggleIsEditEquipmentStatus = (payload: { status: boolean }) =>
  ({
    type: "DESKING_TOGGLE_IS_EDIT_EQUIPMENT",
    payload,
  } as const);

export const deskingActions = {
  removeProductsFromMenu,
  unselectProduct,
  resetMenuBuilder,
  setProposalMenus,
  setFinancePricing,
  removeMenuTerm,
  setMenuOptions,
  setFinancePrograms,
  setProposalCreated,
  setCreditAppLocationData,
  setEquipmentData,
  setProducts,
  addMenuProduct,
  clearState,
  setMenuTerm,
  runJsonEngine,
  updateProducts,
  selectMenuOption,
  removeMenuProduct,
  updateRecalculate,
  setMenuPricing,
  setMenuToBuilder,
  setCurrentEquipmentData,
  setOrderOfProductInMenu,
  addSelectedProductsToMenu,
  updateSectionLayout,
  updateProductConfiguration,
  resetStructureAndMenuOptions,
  toggleIsProposalProductsLoading,
  updateProductConfigurationInMenu,
  setProductsConfigFromExistingMenu,
  updateAllSelectedProductsConfiguration,
  setIsNeedToAutoFillProductsDynamicFields,
  removeFinancePricing,
  removeMenuPricing,
  updateMenuBuilderViewType,
  addMenuTerm,
  toggleIsEditEquipmentStatus,
};
