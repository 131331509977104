import React from "react";
import clsx from "clsx";
import { ModalsKeys } from "global";
import { DndProvider } from "react-dnd";
import { Box } from "@material-ui/core";
import { useModal } from "hooks/useModal";
import { useFormContext } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSaleMode } from "../../../context";
import { deskingActions, deskingSelectors } from "../../../model";
import { BuilderViewType, FinanceProgramFormValues, FormSections, PanelProps, SaleStructureType } from "../../../types";
import {
  CustomerSection,
  EquipmentSection,
  MenuBuilderSection,
  SaleDetailsSection,
  SendViaSection,
  StructureSection,
} from "./sections";

export const PanelForm = (props: Omit<PanelProps, "invoice">) => {
  const { resetField } = useFormContext<FinanceProgramFormValues>();

  const dispatch = useDispatch();

  const { isFinancing, onChangeStructureType } = useSaleMode();
  const { handleOpen } = useModal(ModalsKeys.Confirmation);

  const isBuilderSectionCollapsed = useSelector((state: any) =>
    deskingSelectors.isSectionCollapsed(state, FormSections.MenuBuilder)
  );

  const handleSectionLayout = (section: FormSections, key: "isOpen" | "isCollapsed", value: boolean) => {
    dispatch(deskingActions.updateSectionLayout({ section, key, value }));
  };

  const handleClearStructureAndMenuOptions = () => {
    handleOpen({
      title: "Are you sure you want to reset the proposal builder?",
      message: "This action will remove all configurations from the Finance Program and Menu.",
      onConfirm: () => {
        resetField("financeQuote");
        resetField("displaySettings");

        isFinancing && onChangeStructureType(SaleStructureType.Finance);

        batch(() => {
          dispatch(deskingActions.toggleIsEditEquipmentStatus({ status: false }));
          isFinancing && dispatch(deskingActions.updateMenuBuilderViewType({ type: BuilderViewType.Finance }));
          dispatch(deskingActions.resetStructureAndMenuOptions());
        });
      },
    });
  };

  return (
    <>
      <Box className="row">
        <CustomerSection sectionName={FormSections.Customer} />

        <SaleDetailsSection
          sectionName={FormSections.SaleDetails}
          updateInvoiceAction={props.updateInvoiceAction}
          onClearStructureAndMenuOptions={handleClearStructureAndMenuOptions}
        />
      </Box>

      <EquipmentSection
        sectionName={FormSections.Equipment}
        handleUpdateOpenStatus={(section, nextValue) => {
          handleSectionLayout(section, "isOpen", nextValue);
        }}
        {...props.actions}
      />

      <Box className={clsx(isBuilderSectionCollapsed && "row")}>
        <DndProvider backend={HTML5Backend}>
          <StructureSection
            sectionName={FormSections.Structure}
            onClearStructureAndMenuOptions={handleClearStructureAndMenuOptions}
            handleUpdateCollapse={(section, nextValue) => {
              handleSectionLayout(section, "isCollapsed", nextValue);
            }}
          />

          <MenuBuilderSection
            sectionName={FormSections.MenuBuilder}
            onClearStructureAndMenuOptions={handleClearStructureAndMenuOptions}
            handleUpdateCollapse={(section, nextValue) => {
              handleSectionLayout(section, "isCollapsed", nextValue);
            }}
          />
        </DndProvider>
      </Box>

      <SendViaSection sectionName={FormSections.SendVia} />
    </>
  );
};
