import { useDispatch } from "react-redux";
import { logError } from "utils/logger";
import { useMutation } from "@apollo/react-hooks";
import { useNotifications } from "modules/notification";
import { deskingActions } from "../model";
import { CREATE_PROPOSAL_V2 } from "../api";
import { CreateProposalResponse, CreateProposalVariables } from "../api/types";

export function useProposal() {
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();

  const [onCreateProposal, { loading: isProposalLoading }] = useMutation<
    CreateProposalResponse,
    CreateProposalVariables
  >(CREATE_PROPOSAL_V2);

  const handleCreateProposal = async (variables: CreateProposalVariables) => {
    try {
      const { data } = await onCreateProposal({ variables });

      if (data?.createProposalV2?.id) {
        showNotification("Proposal created!");
        dispatch(deskingActions.setProposalCreated({ isCreated: true }));
      } else {
        showNotification(
          "Proposal not created, Buy Rate, Amount Financed, and/or Term are missing, please check and try again!",
          {
            type: "warning",
            duration: 5000,
          }
        );
      }
    } catch (error) {
      logError(error);
    } finally {
      dispatch(deskingActions.setProposalCreated({ isCreated: false }));
    }
  };
  return {
    isProposalLoading,
    handleCreateProposal,
  };
}
