import React from "react";
import { FormInput, FormInputSelect } from "components/form";
import { EquipmentDynamicFieldsProps } from "../../../types";

export const StringField = ({
  fact,
  value,
  error,
  onChange,
  helperText,
  options = [],
}: EquipmentDynamicFieldsProps) => (
  <>
    {fact.options ? (
      <FormInputSelect
        value={value}
        error={error}
        options={options}
        variant="standard"
        helperText={error ? helperText : ""}
        label={fact.factKey.replace(/_/g, " ")}
        onChange={nextValue => onChange(nextValue)}
      />
    ) : (
      <FormInput
        error={error}
        value={value}
        helperText={error ? helperText : ""}
        label={fact.factKey.replace(/_/g, " ")}
        onChange={nextValue => onChange(nextValue)}
      />
    )}
  </>
);
