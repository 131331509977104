import { useCallback } from "react";
import { ModalsKeys } from "global";
import { useDispatch, useSelector } from "react-redux";
import { ProposalProductsDialogModalData } from "types";
import { NotifyAboutRemovedProductsDialogData } from "modules/desking/types";
import {
  AftermarketSaveAsDialogData,
  AftermarketShareMenuConfirmationDialogData,
} from "modules/aftermarketMenuConstructor/types";
import { FinanceFormModalData, PreviewFinanceFormModalData } from "modules/financeProgram/types";
import { actions, modalsSelectors } from "../redux/modalsReducer";
import { AftermarketPartnerAddDealerDialogData } from "modules/aftermarketPartner/types";
import { CreateUpdateDialogData } from "../modules/invoice";

export type ModalsDataTypes = {
  [ModalsKeys.Load]: any;
  [ModalsKeys.CreateEquipment]: any;
  [ModalsKeys.Confirmation]: any;
  [ModalsKeys.FinanceForm]: FinanceFormModalData;
  [ModalsKeys.PreviewFinanceForm]: PreviewFinanceFormModalData;
  [ModalsKeys.DeskingConfirmSaveDraftDialog]: any;
  [ModalsKeys.ProposalProductsDialog]: ProposalProductsDialogModalData;
  [ModalsKeys.ProductCatalogDialog]: any;
  [ModalsKeys.AftermarketAddEditMenuOptionDialog]: any;
  [ModalsKeys.AftermarketSaveAsDialog]: AftermarketSaveAsDialogData;
  [ModalsKeys.AftermarketShareMenuConfirmationDialog]: AftermarketShareMenuConfirmationDialogData;
  [ModalsKeys.AftermarketPartnerAddDealerDialog]: AftermarketPartnerAddDealerDialogData;
  [ModalsKeys.PrescreenProgramsDialog]: any;
  [ModalsKeys.DeskingNotifyAboutRemovedProductsDialog]: NotifyAboutRemovedProductsDialogData;
  [ModalsKeys.ProposalMenuOptionDetailsDialog]: { menuOption: any };
  [ModalsKeys.ConstructorPricingInfoDialog]: any;
  [ModalsKeys.CreateUpdateInvoiceDialog]: CreateUpdateDialogData;
};

export function useModal<Key extends ModalsKeys>(modalKey: Key) {
  const dispatch = useDispatch();

  const isOpen = useSelector((state: any) => modalsSelectors.selectIsOpen(state, modalKey)) as boolean;
  const data = useSelector((state: any) => modalsSelectors.selectData(state, modalKey)) as ModalsDataTypes[Key];

  const handleOpen = useCallback(
    (data: ModalsDataTypes[Key] = null) => {
      dispatch(actions.onOpenModal(modalKey, data));
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    dispatch(actions.onCloseModal(modalKey));
  }, [dispatch]);

  return {
    data,
    isOpen,
    handleOpen,
    handleClose,
  };
}
