import React from "react";
import { useDispatch } from "react-redux";
import { FormRadioGroup } from "components/form";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useFormContext } from "react-hook-form";
import { useSaleMode } from "../../../../../../context";
import { deskingActions } from "../../../../../../model";
import { existingProgramTypeOptions, styleFormRadioGroupProps } from "../../../../../../constants";
import {
  BuilderViewType,
  CreateProposalFinanceExistingOptions,
  FinanceProgramFormValues,
  SaleType,
} from "../../../../../../types";

export const InitializeWorkflow = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { isCash, onChangeSaleType } = useSaleMode();

  const { control, resetField, setValue } = useFormContext<FinanceProgramFormValues>();

  const handleChangeWorkflowStatus = (value: string) => {
    if (value === CreateProposalFinanceExistingOptions.No) resetField("financeQuote");

    if (isCash) {
      setValue("saleDetails.saleType", SaleType.Financing);
      resetField("financeQuote.menu");
      onChangeSaleType(SaleType.Financing);
      dispatch(deskingActions.updateMenuBuilderViewType({ type: BuilderViewType.Finance }));
      dispatch(deskingActions.resetMenuBuilder());
    }
  };

  return (
    <Box className={classes.container}>
      <Typography variant="subtitle2" component="span">
        Start with Saved Program?
      </Typography>

      <Controller
        control={control}
        name="financeQuote.createProposalWithExistingProgram"
        render={({ field }) => (
          <FormRadioGroup
            row
            options={existingProgramTypeOptions}
            {...styleFormRadioGroupProps}
            {...field}
            onChange={(event, value) => {
              handleChangeWorkflowStatus(value);
              field.onChange(event, value);
            }}
          />
        )}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "1rem",
    display: "flex",
    alignItems: "center",
  },
});
