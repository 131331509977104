import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { deskingSelectors } from "modules/desking/model";
import { useRulesEngine } from "../../../../../context";
import { BooleanField, NumericField, StringField } from "../../../equipmentDynamicFielda";
import {
  EquipmentDynamicFieldsProps,
  FactToCheck,
  FactToCheckTypes,
  ProductConfig,
  ProposalProductCardModes,
  ProposalProductCoverageTermDefaultToFinanceTermOption,
} from "../../../../../types";

interface Props {
  factsToCheck: FactToCheck[];
  mode: ProposalProductCardModes;
  productConfiguration: ProductConfig;
  updateProductConfiguration: (updateValues: Partial<ProductConfig>) => void;
}

export const DynamicFields = ({ factsToCheck, mode, productConfiguration, updateProductConfiguration }: Props) => {
  const classes = useStyles();

  const { engine, handleRunEngin } = useRulesEngine();

  const currentTerm = useSelector(deskingSelectors.term);

  const componentsByType: Record<FactToCheckTypes, FC<EquipmentDynamicFieldsProps>> = {
    string: StringField,
    numeric: NumericField,
    boolean: BooleanField,
  };

  const handleChange = (factKey: string, nextValue: string) => {
    engine.addFact(factKey, nextValue);
    updateProductConfiguration({ [factKey]: nextValue });

    handleRunEngin();
  };

  return (
    <Box className={classes.fields}>
      {factsToCheck.map(fact => {
        const Field = componentsByType[fact.type];

        if (mode === ProposalProductCardModes.Constructor && fact.factKey === "COVERAGE_TERM") {
          if (!fact.options) {
            fact.options = [];
          }
          if (!fact.options.includes(ProposalProductCoverageTermDefaultToFinanceTermOption)) {
            fact.options = fact.options.reverse();
            fact.options.push(ProposalProductCoverageTermDefaultToFinanceTermOption);
            fact.options = fact.options.reverse();
          }
        } else if (mode === ProposalProductCardModes.Desking && fact.factKey === "COVERAGE_TERM") {
          if (productConfiguration?.[fact.factKey] === ProposalProductCoverageTermDefaultToFinanceTermOption) {
            const closestOption = fact?.options?.reduce((prev, curr) => {
              const currParsed = parseInt(curr);
              const prevParsed = parseInt(prev);
              const currentTermParsed = parseInt(currentTerm.term);
              return Math.abs(currParsed - currentTermParsed) < Math.abs(prevParsed - currentTermParsed) ? curr : prev;
            });

            if (closestOption) handleChange(fact.factKey, closestOption);
          }
        }

        return (
          <Field
            fact={fact}
            key={fact.factKey}
            value={productConfiguration?.[fact.factKey] ?? ""}
            options={fact.options?.map(value => ({ label: value, value })) ?? []}
            onChange={nextValue => {
              handleChange(fact.factKey, nextValue);
            }}
          />
        );
      })}
    </Box>
  );
};

const useStyles = makeStyles({
  fields: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
});
