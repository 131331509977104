import _ from "lodash";
import { ValueOf } from "global";
import { formatNumberWithDelimiter } from "utils";
import { Fact, FinanceProgramFormValues } from "../types";

export const equipmentConditions = [
  { label: "New", value: "new" },
  { label: "Used", value: "used" },
  { label: "Demo", value: "demo" },
  { label: "Reconditioned", value: "reconditioned" },
];

export const equipmentYears = _.range(1990, new Date().getFullYear() + 3).map(value => ({ label: `${value}`, value }));

export const equipmentDependentFields: (keyof ValueOf<Pick<FinanceProgramFormValues, "equipment">, "equipment">)[] = [
  "year",
  "make",
  "model",
  "class",
  "stockId",
  "newUsed",
  "quantity",
  "description",
  "retailPrice",
  "serialNumberVin",
  "totalWholesaleCost",
];

export const factsKeyMapOfFormatters: Record<Fact, (value: unknown) => string> = {
  ODOMETER_MILEAGE: value => formatNumberWithDelimiter(value, true),
};
