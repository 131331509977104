import React from "react";
import { FormInput } from "components/form";
import { EquipmentDynamicFieldsProps } from "../../../types";

export const NumericField = ({ fact, value, onChange, helperText, error }: EquipmentDynamicFieldsProps) => (
  <FormInput
    error={error}
    value={value}
    variant="standard"
    helperText={error ? helperText : ""}
    label={fact.factKey.replace(/_/g, " ")}
    onChange={value => onChange(value)}
  />
);
