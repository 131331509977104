import { ActionTypes, Nullable } from "global";
import { immutableUtils } from "utils/immutable";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { DeskingState } from "./types";
import { deskingActions } from "./actions";
import { factsEquipmentEntity } from "../constants";
import { collectFactsToCheckFromProduct, collectFactsSkippedOnProduct } from "../lib";
import {
  AftermarketMenuOption,
  BuilderViewType,
  Equipment,
  FactToCheck,
  OptionName,
  Pricing,
  ProductConfig,
  ProductId,
  ProposalProductCardModes,
  Term,
} from "../types";

type DeskingActions = ActionTypes<typeof deskingActions>;

const initialState: DeskingState = {
  recalculateStep: 0,
  isProposalCreated: false,
  isNeedRunJsonEngine: 0,
  financePrograms: {},
  proposalMenus: {},
  term: { index: 0, term: "" },
  proposalProducts: {
    products: [],
    isLoading: false,
    selectedProducts: {},
    productsFactsToCheck: {},
    productFactsSkipped: {},
    productsConfiguration: {},
    productsConfigurationInMenu: {},
    commonProductsConfiguration: {},
  },
  menuBuilderViewType: BuilderViewType.Finance,
  menuBuilder: {},
  financePrising: {},
  menuPricing: {},
  layout: {
    sections: {},
    selectedMenuOptions: null,
  },
  creditAppLocation: null,
  equipment: {
    all: [],
    current: null,
    isEdit: false,
  },
};

export const deskingReducer = (state = initialState, action: DeskingActions): DeskingState => {
  switch (action.type) {
    case "DESKING_SET_PROPOSALS_PRODUCTS":
      return setProducts(state, action.payload.products);

    case "DESKING_SET_PRODUCTS_CONFIGURATION_FROM_EXISTING_MENU":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: action.payload.terms.reduce<
            DeskingState["proposalProducts"]["productsConfigurationInMenu"]
          >((productsConfigurationInMenu, term) => {
            productsConfigurationInMenu[term] = action.payload.data.reduce<
              Record<OptionName, Record<ProductId, ProductConfig>>
            >((optionConfigAcc, option) => {
              optionConfigAcc[option.name] = collectConfigFromProducts({
                state,
                products: option.products.filter(({ proposalProductId }) =>
                  state.proposalProducts.products
                    .map(availableProduct => availableProduct.proposalProductId)
                    .includes(proposalProductId)
                ),
              });

              return optionConfigAcc;
            }, {});

            return productsConfigurationInMenu;
          }, {}),
        },
      };

    case "DESKING_UPDATE_PRODUCT_CONFIGURATION":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfiguration: {
            ...state.proposalProducts.productsConfiguration,
            [action.payload.productId]: {
              ...(state.proposalProducts.productsConfiguration?.[action.payload.productId] ?? {}),
              ...action.payload.data,
            },
          },
        },
      };

    case "DESKING_UPDATE_ALL_PRODUCTS_CONFIGURATION": {
      return {
        ...state,
        isNeedRunJsonEngine: state.isNeedRunJsonEngine + 1,
        proposalProducts: {
          ...state.proposalProducts,
          commonProductsConfiguration: {
            ...state.proposalProducts.commonProductsConfiguration,
            ...action.payload.data,
          },
          productsConfiguration: {
            ...state.proposalProducts.productsConfiguration,
            ...Object.keys(state.proposalProducts.selectedProducts).reduce<
              DeskingState["proposalProducts"]["productsConfiguration"]
            >((productsConfiguration, productId) => {
              const [ruleKey] = Object.keys(action.payload.data);

              const isCurrentProductSupportRule = !!state.proposalProducts.productsFactsToCheck[productId].find(
                ({ factKey }) => factKey === ruleKey
              );

              if (isCurrentProductSupportRule) {
                productsConfiguration[productId] = state.proposalProducts.productsConfiguration[productId] = {
                  ...state.proposalProducts.productsConfiguration[productId],
                  ...action.payload.data,
                };
              }

              return productsConfiguration;
            }, {}),
          },
          productsConfigurationInMenu: Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce<
            DeskingState["proposalProducts"]["productsConfigurationInMenu"]
          >((productsConfigurationInMenu, [term, configByOption]) => {
            productsConfigurationInMenu[term] = Object.entries(configByOption).reduce<
              Record<OptionName, Record<ProductId, ProductConfig>>
            >((configByOptionAcc, [optionName, configByProductId]) => {
              configByOptionAcc[optionName] = Object.entries(configByProductId).reduce<
                Record<ProductId, ProductConfig>
              >((configByProductIdAcc, [productId, config]) => {
                configByProductIdAcc[productId] = {
                  ...config,
                  ...action.payload.data,
                };
                return configByProductIdAcc;
              }, {});

              return configByOptionAcc;
            }, {});

            return productsConfigurationInMenu;
          }, {}),
        },
      };
    }

    case "DESKING_UPDATE_PRODUCT_CONFIGURATION_IN_MENU":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce<
            DeskingState["proposalProducts"]["productsConfigurationInMenu"]
          >((acc, [term, optionConfig]) => {
            acc[term] = {
              ...optionConfig,
              [action.payload.menuName]: {
                ...(optionConfig?.[action.payload.menuName] ?? {}),
                [action.payload.productId]: {
                  ...(optionConfig?.[action.payload.menuName]?.[action.payload.productId] ?? {}),
                  ...action.payload.data,
                },
              },
            };

            return acc;
          }, {}),
        },
      };

    case "DESKING_TOGGLE_PROPOSALS_PRODUCTS_LOADING":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          isLoading: action.payload.isLoading,
        },
      };

    case "DESKING_UPDATE_RECALCULATE":
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
      };

    case "DESKING_SET_CREDIT_APP_LOCATION_DATA":
      return {
        ...state,
        creditAppLocation: action.payload.creditAppLocationData,
      };

    case "DESKING_SET_EQUIPMENT_DATA":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          commonProductsConfiguration: collectConfigFromEquipment(action.payload.equipments[0]),
        },
        equipment: {
          ...state.equipment,
          all: action.payload.equipments,
          current: action.payload.equipments[0],
        },
      };

    case "DESKING_SET_CURRENT_EQUIPMENT_DATA":
      const equipment = state.equipment.all.find(({ equipmentId }) => equipmentId === action.payload.id) ?? null;

      return {
        ...state,
        equipment: {
          ...state.equipment,
          current: equipment,
        },
        proposalProducts: {
          ...state.proposalProducts,
          commonProductsConfiguration: collectConfigFromEquipment(equipment),
        },
      };

    case "DESKING_TOGGLE_IS_EDIT_EQUIPMENT":
      return {
        ...state,
        equipment: {
          ...state.equipment,
          isEdit: action.payload.status,
        },
      };

    case "DESKING_SET_FINANCE_PROGRAMS":
      return {
        ...state,
        financePrograms: action.payload.programs.reduce<DeskingState["financePrograms"]>((acc, product) => {
          acc[product.financeProgramId] = product;
          return acc;
        }, {}),
      };

    case "DESKING_UPDATE_MENU_BUILDER_VIEW_TYPE":
      return {
        ...state,
        menuBuilderViewType: action.payload.type,
      };

    case "DESKING_SET_PROPOSAL_MENUS":
      return {
        ...state,
        proposalMenus: action.payload.menuOptions.reduce<DeskingState["proposalMenus"]>((proposalMenus, menu) => {
          proposalMenus[menu.proposalMenuId] = {
            ...menu,
            menuOptions: menu.menuOptions.map(option => ({
              ...option,
              products: option.products.toSorted((a: any, b: any) => a.ordinal - b.ordinal),
            })),
          };

          return proposalMenus;
        }, {}),
      };

    case "DESKING_RESET_MENU_BUILDER":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {},
          commonProductsConfiguration: collectConfigFromEquipment(state.equipment.current),
        },
        menuBuilder: {},
        menuPricing: {},
        financePrising: {},
      };

    case "DESKING_SET_MENU_TO_BUILDER":
      return {
        ...state,
        menuBuilder: action.payload.terms.reduce<DeskingState["menuBuilder"]>((menuBuilder, term) => {
          menuBuilder[term] = state.proposalMenus[action.payload.id];
          return menuBuilder;
        }, {}),
      };

    case "DESKING_SET_FINANCE_PRICING":
      return {
        ...state,
        financePrising: action.payload.results.reduce<DeskingState["financePrising"]>((financePrising, results) => {
          financePrising[results.term] = results;
          return financePrising;
        }, {}),
      };

    case "DESKING_REMOVE_FINANCE_PRICING": {
      return {
        ...state,
        financePrising: !action.payload.terms.length
          ? {}
          : Object.entries(state.financePrising).reduce<DeskingState["financePrising"]>((results, [term, pricing]) => {
              if (action.payload.terms.includes(term)) {
                results[term] = pricing;
              }
              return results;
            }, {}),
      };
    }

    case "DESKING_SET_MENU_PRICING":
      return {
        ...state,
        menuBuilder: Object.entries(state.menuBuilder).reduce<DeskingState["menuBuilder"]>(
          (menuBuilder, [term, builder]) => {
            menuBuilder[term] = {
              ...builder,
              menuOptions: builder.menuOptions.map(option => {
                const pricing = action.payload.results.find(pricing => String(pricing.term) === term);

                if (!pricing || !pricing.menu) return option;

                return {
                  ...option,
                  menuOptionDetail:
                    pricing.menu.menuOptions.find(({ name }) => name === option.name)?.menuOptionDetail ?? "",
                };
              }),
            };
            return menuBuilder;
          },
          {}
        ),
        menuPricing: action.payload.results.reduce<DeskingState["menuPricing"]>((menuPricing, result) => {
          if (result.menu?.name) {
            menuPricing[result.term] = result.menu.menuOptions.reduce<Record<Term, Pricing>>((pricing, menuOption) => {
              pricing[menuOption.name] = menuOption.payment;
              return pricing;
            }, {});
          }

          return menuPricing;
        }, {}),
      };

    case "DESKING_REMOVE_MENU_PRICING": {
      return {
        ...state,
        menuPricing: !action.payload.terms.length
          ? {}
          : Object.entries(state.menuPricing).reduce<DeskingState["menuPricing"]>((results, [term, menuPricing]) => {
              if (action.payload.terms.includes(term)) {
                results[term] = menuPricing;
              }
              return results;
            }, {}),
      };
    }

    case "DESKING_UPDATE_PROPOSALS_PRODUCTS":
      return {
        ...state,
        equipment: {
          ...state.equipment,
          isEdit: true,
        },
        proposalProducts: {
          ...state.proposalProducts,
          selectedProducts: action.payload.products.reduce<DeskingState["proposalProducts"]["selectedProducts"]>(
            (selectedProducts, product) => {
              selectedProducts[product.proposalProductId] = product;
              return selectedProducts;
            },
            {}
          ),
          productsConfiguration: {
            ...state.proposalProducts.productsConfiguration,
            ...collectConfigFromProducts({ state, products: action.payload.products }),
          },
        },
      };

    case "DESKING_UNSELECT_PROPOSALS_PRODUCTS":
      return unselectProduct(state, action.payload.productId);

    case "DESKING_SET_PROPOSAL_CREATED":
      return {
        ...state,
        isProposalCreated: action.payload.isCreated,
      };

    case "DESKING_SET_MENU_TERM":
      return {
        ...state,
        term: {
          ...state.term,
          ...action.payload,
        },
      };

    case "DESKING_SET_MENU_OPTIONS":
      return {
        ...state,
        menuBuilder: Object.entries(state.menuBuilder).reduce<DeskingState["menuBuilder"]>((acc, [term, builder]) => {
          acc[term] = {
            ...builder,
            menuOptions: action.payload.menuOptions,
          };

          return acc;
        }, {}),
      };

    case "DESKING_SET_ORDER_OF_PRODUCT_IN_MENU":
      return {
        ...state,
        menuBuilder: {
          ...state.menuBuilder,
          [state.term.term]: {
            ...state.menuBuilder[state.term.term],
            menuOptions: state.menuBuilder[state.term.term].menuOptions.reduce<AftermarketMenuOption[]>(
              (menuOptions, option) => {
                if (option.name === action.payload.menuName) {
                  menuOptions.push({
                    ...option,
                    products: immutableUtils.swapItems(option.products, action.payload.from, action.payload.to),
                  });
                } else menuOptions.push(option);

                return menuOptions;
              },
              []
            ),
          },
        },
      };

    case "DESKING_ADD_PRODUCT":
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            ...Object.entries(state.menuBuilder).reduce<
              DeskingState["proposalProducts"]["productsConfigurationInMenu"]
            >((termsAcc, [term, builder]) => {
              termsAcc[term] = builder.menuOptions.reduce<Record<OptionName, Record<ProductId, ProductConfig>>>(
                (optionsAcc, option) => {
                  const isProductAlreadyInMenu = option.products
                    .map(({ proposalProductId }) => proposalProductId)
                    .includes(action.payload.product.proposalProductId);

                  if (option.name === action.payload.menuType && !isProductAlreadyInMenu) {
                    optionsAcc[option.name] = {
                      ...state.proposalProducts.productsConfigurationInMenu?.[term]?.[option.name],
                      [action.payload.product.proposalProductId]:
                        state.proposalProducts.productsConfiguration[action.payload.product.proposalProductId],
                    };
                  } else {
                    optionsAcc[option.name] =
                      state.proposalProducts.productsConfigurationInMenu?.[term]?.[option.name] ?? {};
                  }

                  return optionsAcc;
                },
                {}
              );

              return termsAcc;
            }, {}),
          },
        },
        menuBuilder: Object.entries(state.menuBuilder).reduce<DeskingState["menuBuilder"]>((acc, [term, builder]) => {
          acc[term] = {
            ...builder,
            menuOptions: builder.menuOptions.map(option => {
              const isProductAlreadyInMenu = option.products
                .map(({ proposalProductId }) => proposalProductId)
                .includes(action.payload.product.proposalProductId);

              if (option.name === action.payload.menuType && !isProductAlreadyInMenu) {
                return {
                  ...option,
                  products: [...option.products, action.payload.product],
                };
              }

              return option;
            }),
          };

          return acc;
        }, {}),
      };

    case "DESKING_REMOVE_PRODUCT":
      return removeProductFromMenu(state, action.payload.menuName, action.payload.productId);

    case "DESKING_UPDATE_SECTION_LAYOUT":
      return {
        ...state,
        layout: {
          ...state.layout,
          sections: {
            ...state.layout.sections,
            [action.payload.section]: {
              ...(state.layout.sections?.[action.payload.section] ?? {}),
              [action.payload.key]: action.payload.value,
            },
          },
        },
      };

    case "DESKING_LAYOUT_SELECT_MENU_OPTION":
      return {
        ...state,
        layout: {
          ...state.layout,
          selectedMenuOptions:
            state.layout.selectedMenuOptions === action.payload.optionName ? null : action.payload.optionName,
        },
      };

    case "DESKING_CLEAR":
      return initialState;

    case "DESKING_RESET_STRUCTURE_AND_MENU_OPTIONS":
      return {
        ...initialState,
        proposalProducts: {
          ...initialState.proposalProducts,
          products: state.proposalProducts.products,
          productsFactsToCheck: state.proposalProducts.productsFactsToCheck,
          productsConfiguration: state.proposalProducts.productsConfiguration,
        },
        equipment: state.equipment,
        proposalMenus: state.proposalMenus,
        financePrograms: state.financePrograms,
      };

    case "DESKING_ADD_SELECTED_PRODUCTS_TO_MENU":
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            ...Object.entries(state.menuBuilder).reduce<
              DeskingState["proposalProducts"]["productsConfigurationInMenu"]
            >((termsAcc, [term, builder]) => {
              termsAcc[term] = builder.menuOptions.reduce<Record<OptionName, Record<ProductId, ProductConfig>>>(
                (optionsAcc, option) => {
                  optionsAcc[option.name] = {
                    ...(state.proposalProducts.productsConfigurationInMenu?.[term]?.[option.name] ?? {}),
                    ...Object.values(state.proposalProducts.selectedProducts).reduce<Record<ProductId, ProductConfig>>(
                      (productsAcc, product) => {
                        const existingProductConfig =
                          state.proposalProducts.productsConfigurationInMenu?.[term]?.[option.name]?.[
                            product.proposalProductId
                          ];

                        if (!!existingProductConfig) {
                          productsAcc[product.proposalProductId] = existingProductConfig;
                        } else {
                          productsAcc[product.proposalProductId] =
                            state.proposalProducts.productsConfiguration[product.proposalProductId];
                        }

                        return productsAcc;
                      },
                      {}
                    ),
                  };
                  return optionsAcc;
                },
                {}
              );

              return termsAcc;
            }, {}),
          },
        },
        menuBuilder: Object.entries(state.menuBuilder).reduce<DeskingState["menuBuilder"]>((acc, [term, builder]) => {
          acc[term] = {
            ...builder,
            menuOptions: builder.menuOptions.map(option => ({
              ...option,
              products: [
                ...option.products,
                ...state.proposalProducts.products
                  .filter(({ proposalProductId }) =>
                    Object.keys(state.proposalProducts.selectedProducts).includes(proposalProductId)
                  )
                  .filter(
                    ({ proposalProductId }) =>
                      !option.products.map(({ proposalProductId }) => proposalProductId).includes(proposalProductId)
                  ),
              ],
            })),
          };

          return acc;
        }, {}),
      };

    case "DESKING_ADD_TERM_FROM_MENU_BUILDER":
      return {
        ...state,
        menuBuilder: {
          ...state.menuBuilder,
          [action.payload.term]: state.proposalMenus[action.payload.menuId],
        },
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            [action.payload.term]: state.proposalMenus[action.payload.menuId].menuOptions.reduce<
              Record<OptionName, Record<ProductId, ProductConfig>>
            >((optionConfigAcc, option) => {
              optionConfigAcc[option.name] = collectConfigFromProducts({
                state,
                products: option.products.filter(({ proposalProductId }) =>
                  state.proposalProducts.products
                    .map(availableProduct => availableProduct.proposalProductId)
                    .includes(proposalProductId)
                ),
              });

              return optionConfigAcc;
            }, {}),
          },
        },
      };

    case "DESKING_REMOVE_TERM_FROM_MENU_BUILDER":
      return {
        ...state,
        menuBuilder: immutableUtils.removeKeyFromObj(state.menuBuilder, action.payload.term),
        financePrising: immutableUtils.removeKeyFromObj(state.financePrising, action.payload.term),
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: immutableUtils.removeKeyFromObj(
            state.proposalProducts.productsConfigurationInMenu,
            action.payload.term
          ),
        },
      };

    case "DESKING_RUN_ENGINE":
      return {
        ...state,
        isNeedRunJsonEngine: state.isNeedRunJsonEngine + 1,
      };

    case "DESKING_REMOVE_PRODUCTS_FROM_MENU":
      return {
        ...state,
        menuBuilder: Object.entries(state.menuBuilder).reduce<DeskingState["menuBuilder"]>(
          (menuBuilder, [term, menu]) => {
            menuBuilder[term] = {
              ...menu,
              menuOptions: menu.menuOptions.reduce<AftermarketMenuOption[]>((menuOptions, option) => {
                menuOptions.push({
                  ...option,
                  products: option.products.filter(
                    ({ proposalProductId }) => !action.payload.ids.includes(proposalProductId)
                  ),
                });

                return menuOptions;
              }, []),
            };

            return menuBuilder;
          },
          {}
        ),
      };

    default:
      return state;
  }
};

function collectConfigFromEquipment(equipment: Equipment | null) {
  return equipment
    ? Object.entries(factsEquipmentEntity).reduce<Partial<ProductConfig>>((commonConfig, [key, fact]) => {
        if (equipment?.[key]) commonConfig[fact] = equipment[key];
        return commonConfig;
      }, {})
    : {};
}

function setProducts(state: DeskingState, products: ProposalProduct[]): DeskingState {
  const productFactDetails = products.reduce<{
    productFactsToCheck: Record<string, FactToCheck[]>;
    productFactsSkipped: Record<string, FactToCheck[]>;
  }>(
    (acc, product) => {
      acc.productFactsToCheck[product.proposalProductId] = collectFactsToCheckFromProduct(product);
      acc.productFactsSkipped[product.proposalProductId] = collectFactsSkippedOnProduct(
        product,
        ProposalProductCardModes.Desking
      );
      return acc;
    },
    {
      productFactsToCheck: {},
      productFactsSkipped: {},
    }
  );

  return {
    ...state,
    proposalProducts: {
      ...state.proposalProducts,
      productsFactsToCheck: productFactDetails.productFactsToCheck,
      productFactsSkipped: productFactDetails.productFactsSkipped,
      products,
      productsConfiguration: collectConfigFromProducts({
        state,
        products,
        productsFactsToCheck: productFactDetails.productFactsToCheck,
      }),
    },
  };
}

function collectConfigFromProducts(params: {
  state: DeskingState;
  products: ProposalProduct[];
  productsFactsToCheck?: Nullable<Record<string, FactToCheck[]>>;
}): Record<string, ProductConfig> {
  const { state, products, productsFactsToCheck = null } = params;

  return products.reduce<Record<string, ProductConfig>>((acc, product) => {
    const dynamicConfigValues = Object.entries({
      ...state.proposalProducts.commonProductsConfiguration,
    }).reduce<Record<string, ProductConfig>>((config, [key, value]) => {
      const isCurrentProductSupportRule = !!(productsFactsToCheck ?? state.proposalProducts.productsFactsToCheck)[
        product.proposalProductId
      ].find(({ factKey }) => factKey === key);

      if (!isCurrentProductSupportRule) return config;

      config[key] = value;

      return config;
    }, {});

    acc[product.proposalProductId] = {
      cost: product?.cost ?? 0,
      contractTerm: state.term.term,
      coverageTerm: state.term.term,
      retailCost: product?.retailCost ?? 0,
      markup: product?.markup ?? { markup: 0, type: "FLAT" },
      ...(state.proposalProducts.productsConfiguration[product.proposalProductId] ?? {}),
      ...dynamicConfigValues,
      ...(product?.aftermarketProduct?.config?.criteriaValues?.[0] ?? {}),
    };

    return acc;
  }, {});
}

function unselectProduct(state: DeskingState, unselectProductId: string): DeskingState {
  const product = state.proposalProducts.products.find(
    ({ proposalProductId }) => proposalProductId === unselectProductId
  );

  const amountOfSelectedProducts = Object.keys(state.proposalProducts.selectedProducts).length;
  const amountOfProductsInMenu = Object.values(state.menuBuilder)
    .map(menuBuilder => menuBuilder.menuOptions.map(option => option.products))
    .flat(2).length;

  const isAllProductsUnselect = amountOfSelectedProducts + amountOfProductsInMenu === 1;

  return {
    ...state,
    equipment: {
      ...state.equipment,
      isEdit: false,
    },
    proposalProducts: {
      ...state.proposalProducts,
      commonProductsConfiguration: isAllProductsUnselect ? {} : state.proposalProducts.commonProductsConfiguration,
      selectedProducts: Object.entries(state.proposalProducts.selectedProducts).reduce<
        DeskingState["proposalProducts"]["selectedProducts"]
      >((acc, [productId, product]) => {
        if (productId === unselectProductId) return acc;
        acc[productId] = product;
        return acc;
      }, {}),
      productsConfiguration: {
        ...state.proposalProducts.productsConfiguration,
        [unselectProductId]: {
          cost: product?.cost ?? 0,
          contractTerm: state.term.term,
          coverageTerm: state.term.term,
          retailCost: product?.retailCost ?? 0,
          markup: product?.markup ?? { markup: 0, type: "FLAT" },
        },
      },
    },
  };
}

function removeProductFromMenu(state: DeskingState, menuType: string, productId: string): DeskingState {
  const amountOfSelectedProducts = Object.keys(state.proposalProducts.selectedProducts).length;
  const amountOfProductsInMenu = Object.values(state.menuBuilder)
    .map(menuBuilder => menuBuilder.menuOptions.map(option => option.products))
    .flat(2).length;

  const isAllProductsUnselect = amountOfSelectedProducts + amountOfProductsInMenu === 1;

  return {
    ...state,
    recalculateStep: state.recalculateStep + 1,
    proposalProducts: {
      ...state.proposalProducts,
      commonProductsConfiguration: isAllProductsUnselect ? {} : state.proposalProducts.commonProductsConfiguration,
      productsConfigurationInMenu: {
        ...state.proposalProducts.productsConfigurationInMenu,
        [state.term.term]: {
          ...state.proposalProducts.productsConfigurationInMenu?.[state.term.term],
          [menuType]: immutableUtils.removeKeyFromObj(
            state.proposalProducts.productsConfigurationInMenu[state.term.term][menuType],
            productId
          ),
        },
      },
    },
    menuBuilder: {
      ...state.menuBuilder,
      [state.term.term]: {
        ...state.menuBuilder[state.term.term],
        menuOptions: state.menuBuilder[state.term.term].menuOptions.map(option => {
          if (option.name === menuType) {
            return {
              ...option,
              products: option.products.filter(product => product.proposalProductId !== productId),
            };
          }

          return option;
        }),
      },
    },
  };
}
