import React, { FC } from "react";
import { HelpOutlined, Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Box, Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import { deskingSelectors } from "../../../../../model";
import { dynamicFieldErrorText, factsExcludeEquipmentEntity } from "../../../../../constants";
import { BooleanField, NumericField, StringField } from "../../../../components/equipmentDynamicFielda";
import {
  EquipmentDynamicFieldsProps,
  FactToCheckTypes,
  ProductsDynamicFieldsRender,
  ProposalProductCoverageTermDefaultToFinanceTermOption,
} from "../../../../../types";
import { FACT_NAMES_STRING } from "@trnsact/business-criteria";
import { useModal } from "hooks/useModal";
import { ModalsKeys } from "global";

interface Props {
  onChangeAllProductsConfig: (configField: string, nextValue: string) => void;
}

export const ProductsDynamicFields = ({ onChangeAllProductsConfig }: Props) => {
  const classes = useStyles();

  const productsFactsForCheckArray = useSelector(deskingSelectors.productsFactsForCheckArray);
  const commonConfig = useSelector(deskingSelectors.commonProductsConfiguration);

  const { handleOpen: handleOpenConstructorPricingInfoDialog } = useModal(ModalsKeys.ConstructorPricingInfoDialog);

  const componentsByType: Record<FactToCheckTypes, FC<EquipmentDynamicFieldsProps>> = {
    string: StringField,
    numeric: NumericField,
    boolean: BooleanField,
  };

  const renderConfig = productsFactsForCheckArray.reduce<ProductsDynamicFieldsRender>(
    (acc, fact) => {
      factsExcludeEquipmentEntity.forEach(targetFactKey => {
        if (fact.factKey === targetFactKey) {
          acc.meta[targetFactKey] = {
            options: [...(acc.meta[targetFactKey]?.options ?? []), ...(fact.options ?? [])],
          };
        }

        if (fact.factKey === targetFactKey && !acc.render[targetFactKey]) {
          acc.render[targetFactKey] = {
            fact,
            fieldComponent: componentsByType[fact.type],
          };
        }

        return acc;
      });

      return acc;
    },
    { render: {}, meta: {} }
  );

  return (
    <Box className={classes.container}>
      <Box className="labelContainer">
        <Typography component="span" variant="subtitle2">
          Product Configuration Required
        </Typography>

        <Tooltip title="All values will be apply for all selected products">
          <Info color="primary" fontSize="small" />
        </Tooltip>
      </Box>

      <Box className="fieldsContainer">
        {Object.entries(renderConfig.render).map(([factType, { fieldComponent: FieldComponent, fact }]) => {
          if (fact.factKey === FACT_NAMES_STRING.COVERAGE_TERM) {
            return (
              <Tooltip title="Clicking this will set the coverage term for all products to the Finance Term of the deal. If there is no exact match, the closest term is selected.">
                <Button
                  variant="outlined"
                  style={{ width: "70%" }}
                  size={"small"}
                  onClick={() => {
                    onChangeAllProductsConfig(fact.factKey, ProposalProductCoverageTermDefaultToFinanceTermOption);
                  }}
                >
                  Set Coverage Term to Finance Term
                </Button>
              </Tooltip>
            );
          } else {
            return (
              <FieldComponent
                fact={fact}
                key={factType}
                helperText={dynamicFieldErrorText}
                error={!commonConfig?.[fact.factKey]}
                value={commonConfig?.[fact.factKey] ?? ""}
                onChange={nextValue => onChangeAllProductsConfig(fact.factKey, nextValue)}
                options={Array.from(new Set(renderConfig.meta[fact.factKey].options)).map(value => ({
                  label: value,
                  value,
                }))}
              />
            );
          }
        })}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "0.5rem",
    display: "flex",
    flexDirection: "column",

    "& .labelContainer": {
      gap: "1rem",
      display: "flex",
      alignItems: "center",
    },

    "& .fieldsContainer": {
      gap: "0.5rem",
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
});
