import React from "react";
import { Box, Typography } from "@material-ui/core";
import { PaymentInfoRenderConfig } from "../../types";

export const PaymentOptionsInfoItem = ({ formatter, value, label }: PaymentInfoRenderConfig) => {
  return (
    <Box className="infoItem">
      <Typography component="span" variant="body1" color="textSecondary">
        {label}
      </Typography>

      <Typography component="span" variant="body2">
        {formatter ? formatter(value) : value}
      </Typography>
    </Box>
  );
};
