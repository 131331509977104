import React, { ReactNode } from "react";
import clsx from "clsx";
import { Nullable } from "global";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SaleType } from "../../../types";

interface Props {
  isLoading: boolean;
  saleType?: SaleType;
  cashTotalPrice: Nullable<number | string>;
  financeTotalPrice: Nullable<number | string>;
}

export const MenuOptionCardFooter = ({
  isLoading,
  cashTotalPrice,
  financeTotalPrice,
  saleType = SaleType.Financing,
}: Props) => {
  const classes = useStyles();

  const renderBySaleType: Record<SaleType, ReactNode> = {
    [SaleType.Cash]: (
      <Typography component="span" variant="h6">
        {cashTotalPrice}
      </Typography>
    ),
    [SaleType.Financing]: (
      <>
        <Box className={clsx("monthPrice", { monthPriceCalculating: isLoading })}>
          <Typography component="span" variant="h6">
            {financeTotalPrice}
          </Typography>

          <Typography component="span" variant="caption" color="textSecondary">
            per month
          </Typography>
        </Box>
      </>
    ),
  };

  return <Box className={classes.cardMonthPrice}>{renderBySaleType[saleType]}</Box>;
};

const useStyles = makeStyles({
  cardMonthPrice: {
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .monthPrice": {
      gap: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& .monthPriceCalculating": {
      filter: "blur(2px)",
    },
  },
});
